import { getUserLocales } from 'get-user-locale'
import Cookies from 'js-cookie'

export const COOKIE_LOCALE_KEY = 'COOKIE_LOCALE_KEY'
export const DEFAULT_LOCALE = 'en'
export const AVAILABLE_LOCALES = {
  en: 'en',
  pl: 'pl',
}

let locale
export const getLocale = () => {
  locale =
    locale ||
    getURLLocale() ||
    getLocaleFromCookies() ||
    getBrowserLocale() ||
    DEFAULT_LOCALE
  return locale
}

const getURLLocale = () => {
  const localeFromPathname = window.location.pathname.replace('/', '')
  if (localeFromPathname in AVAILABLE_LOCALES) {
    return localeFromPathname
  }
}

const getLocaleFromCookies = () => {
  const locale = Cookies.get(COOKIE_LOCALE_KEY)
  return locale in AVAILABLE_LOCALES && locale
}

const getBrowserLocale = () =>
  getUserLocales().find(locale => locale in AVAILABLE_LOCALES)

export const setLocale = locale => {
  Cookies.set(COOKIE_LOCALE_KEY, locale, { expires: 365 })

  if (window.location.pathname === '/') {
    window.location.reload()
  } else {
    window.location.href = window.location.origin
  }
}
