import React from 'react'
import { useIntl, defineMessage } from 'react-intl'
import { TrackingSection } from '../Common'

const JSX_NBSP = '\u00A0'

const devices = [
  {
    id: 1,
    image: '/img/devices/industrial-tablets.png',
    header: {
      text: defineMessage({
        id: 'discoverSection.devices.1.headerTitle',
        defaultMessage: 'Dedicated devices',
      }),
    },
    title: defineMessage({
      id: 'discoverSection.devices.1.title',
      defaultMessage: 'Industrial Tablets',
    }),
    info: [
      defineMessage({
        id: 'discoverSection.devices.1.info.1',
        defaultMessage: 'Drop resistance from 1.2 m',
      }),
      defineMessage({
        id: 'discoverSection.devices.1.info.2',
        defaultMessage: 'Up to 8h battery life',
      }),
      defineMessage({
        id: 'discoverSection.devices.1.info.3',
        defaultMessage: 'IP65 certified',
      }),
      defineMessage({
        id: 'discoverSection.devices.1.info.4',
        defaultMessage: 'Barcode scanner',
      }),
      defineMessage({
        id: 'discoverSection.devices.1.info.5',
        defaultMessage: 'RFID scanner',
      }),
    ],
  },
  {
    id: 2,
    image: '/img/devices/industrial-control-screen.png',
    title: defineMessage({
      id: 'discoverSection.devices.2.title',
      defaultMessage: 'Industrial Control Screen',
    }),
    info: [
      defineMessage({
        id: 'discoverSection.devices.2.info.1',
        defaultMessage: 'Wall mount',
      }),
      defineMessage({
        id: 'discoverSection.devices.2.info.2',
        defaultMessage: 'Touch screen',
      }),
      defineMessage({
        id: 'discoverSection.devices.2.info.3',
        defaultMessage: 'IP65 certified',
      }),
      defineMessage({
        id: 'discoverSection.devices.2.info.4',
        defaultMessage: 'RS232 pin connector',
      }),
      defineMessage({
        id: 'discoverSection.devices.2.info.5',
        defaultMessage: 'Wi-Fi, Bluetooth',
      }),
    ],
  },
  {
    id: 3,
    image: '/img/devices/ekanban-magic-box.png',
    header: {
      image: '/img/devices/magic-box-logo.png',
      imageAlt: 'MagicBox',
    },
    title: defineMessage({
      id: 'discoverSection.devices.3.title',
      defaultMessage: 'eKANBAN MagicBox',
    }),
    info: [
      defineMessage({
        id: 'discoverSection.devices.3.info.1',
        defaultMessage: 'Easy to use',
      }),
      defineMessage({
        id: 'discoverSection.devices.3.info.2',
        defaultMessage: 'Universal application',
      }),
      defineMessage({
        id: 'discoverSection.devices.3.info.3',
        defaultMessage: 'Custom functions',
      }),
      defineMessage({
        id: 'discoverSection.devices.3.info.4',
        defaultMessage: 'LED Screen',
      }),
      defineMessage({
        id: 'discoverSection.devices.3.info.5',
        defaultMessage: 'Barcode scanner',
      }),
    ],
  },
]

const createArrow = (className, icon) => `
  <button class="${className}">
    <i class="fa fa-2x ${icon}"></i>
  </button>
`

const nextArrow = createArrow('slick-next', 'fa-chevron-right')
const prevArrow = createArrow('slick-prev', 'fa-chevron-left')

const DiscoverSection = () => {
  const slickRef = React.useRef()
  React.useEffect(() => {
    window.$(slickRef.current).slick({
      dots: true,
      nextArrow,
      prevArrow,
    })
  }, [])

  return (
    <TrackingSection
      section='Devices'
      className='section discover-area bg-gray overflow-hidden ptb_100'
    >
      <div className='container-fluid devices-slider' ref={slickRef}>
        {devices.map(device => (
          <Device key={device.id} device={device} />
        ))}
      </div>
    </TrackingSection>
  )
}

const Device = ({ device }) => {
  const intl = useIntl()

  const renderHeader = () => {
    if (device.header && device.header.image) {
      return (
        <img
          src={device.header.image}
          alt={device.header.imageAlt}
          className='mb-4'
        />
      )
    }

    return (
      <h2 className='pb-4 text-white'>
        {device.header && device.header.text
          ? intl.formatMessage(device.header.text)
          : JSX_NBSP}
      </h2>
    )
  }

  return (
    <div className='container-fluid px-0'>
      <div className='container px-0'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-lg-6 order-2 order-lg-1'>
            <div className='service-thumb discover-thumb mx-auto text-center mt-5 mt-lg-0'>
              <img src={device.image} alt={intl.formatMessage(device.title)} />
            </div>
          </div>
          <div className='col-12 col-lg-6 order-1 order-lg-2'>
            <div className='discover-text px-0 px-lg-2 pt-4 pt-lg-0'>
              {renderHeader()}
              <p className='pb-3 font-weight-bold text-white device-text'>
                {intl.formatMessage(device.title)}
              </p>
              <ul className='check-list'>
                {device.info.map((msg, idx) => {
                  return (
                    <li key={`dt_${idx}`} className='py-2'>
                      <div className='list-box media text-white'>
                        <span className='icon align-self-center'>
                          <i className='fa fa-check' />
                        </span>
                        <span className='media-body pl-4 device-text'>
                          {intl.formatMessage(msg)}
                        </span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscoverSection
