import React, { Component } from 'react'

import Header from '../components/HeaderSection/Header'
import HeroSection from '../components/HeroSection/HeroTwo'
import HowItWorksSection from '../components/HowItWorksSection/HowItWorks'
import FeatureSection from '../components/Features/FeatureTwo'
import DiscoverSection from '../components/DiscoverSection/DiscoverTwo'
import ServiceSection from '../components/ServiceSection/ServiceTwo'
import Work from '../components/WorkSection/Work'
import ScreenshotSection from '../components/ScreenshotSection/ScreenshotsTwo'
import PricingSection from '../components/PricingSection/PricingOne'
import FaqSection from '../components/FaqSection/FaqTwo'
import Download from '../components/DownloadSection/Download'
import ContactSection from '../components/ContactSection/Contact'
import FooterSection from '../components/FooterSection/Footer'

class ThemeTwo extends Component {
  render() {
    return (
      <div className='homepage-2'>
        {/*====== Scroll To Top Area Start ======*/}
        <div id='scrollUp' title='Scroll To Top'>
          <i className='fas fa-arrow-up' />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className='main'>
          <Header />
          <HeroSection />
          <HowItWorksSection />
          <FeatureSection />
          <DiscoverSection />
          <ServiceSection />
          <Work />
          <ScreenshotSection />
          <PricingSection />
          <FaqSection />
          <Download />
          <ContactSection />
          <FooterSection />
        </div>
      </div>
    )
  }
}

export default ThemeTwo
