import ReactGA from 'react-ga'

const MIN_WATCHING_TIME_SEC = 5

class ReadingTracking {
  constructor(section) {
    this.section = section
  }

  onInteraction({ isIntersecting }) {
    if (isIntersecting) {
      this.start()
    } else if (this.started) {
      this.end()
    }
  }

  start() {
    this.started = setTimeout(() => {
      sendEvent({ section: this.section })
      this.end()
    }, MIN_WATCHING_TIME_SEC * 1000)
  }

  end() {
    clearTimeout(this.started)
    delete this.started
  }
}

const sendEvent = ({ section }) => {
  ReactGA.event(
    {
      category: 'Watching Section',
      action: section,
      label: `Watching ${section} section`,
    },
    ['all', 'one']
  )
}

export default ReadingTracking
