import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import { TrackingSection } from '../Common'

const screenshotImageAlt = defineMessage({
  id: 'screenshotsSection.imageAlt',
  defaultMessage: 'eKANBAN app',
})

const data = ['/img/screenshot_1.jpg', '/img/screenshot_2.jpg']

const ScreenshotSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='Interface'
      id='interface-section'
      className='section screenshots-area ptb_100'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-7'>
            {/* Section Heading */}
            <div className='section-heading text-center'>
              <h2 className=''>
                <FormattedMessage
                  id='screenshotsSection.heading'
                  defaultMessage='Easy to use interface'
                />
              </h2>
              <p className='mt-4'>
                <FormattedMessage
                  id='screenshotsSection.headingText'
                  defaultMessage='Beautiful and easy-to-use user interface. Thanks to the fully responsive design, each employee will easily handle the eKABNAN system.'
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            {/* App Screenshot Slider Area */}
            <div
              className='app-screenshots'
              data-slick='{"dots":false,"slidesToShow":2,"slidesToScroll":1}'
            >
              {/* Single Screenshot Item */}
              {data.map((image, idx) => {
                return (
                  <div
                    key={`so_${idx}`}
                    href={image}
                    data-fancybox='screenshots'
                    className='single-screenshot'
                  >
                    <img
                      src={image}
                      alt={intl.formatMessage(screenshotImageAlt)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default ScreenshotSection
