import React from 'react'
import { IntlProvider } from 'react-intl'

import { getLocale, DEFAULT_LOCALE } from './locale'
import en from './lang/en.json'
import pl from './lang/pl.json'

const messages = { en, pl }
const mainLocale = getLocale()

const Intl = ({ children, locale: propLocale }) => {
  const locale = propLocale || mainLocale

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
    >
      {children}
    </IntlProvider>
  )
}

export default React.memo(Intl)
