import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import { TrackingSection } from '../Common'

const implementation = [
  {
    id: 1,
    title: defineMessage({
      id: 'workSection.step.1.title',
      defaultMessage: 'Contact',
    }),
    content: defineMessage({
      id: 'workSection.step.1.content',
      defaultMessage:
        'Fill out the contact form below, giving the company name, number of employees, as well as the estimated number of system users and the processes covered by eKANBAN App.',
    }),
    icon: '/assets/svg/work/mail.svg',
  },
  {
    id: 2,
    title: defineMessage({
      id: 'workSection.step.2.title',
      defaultMessage: 'Meeting',
    }),
    content: defineMessage({
      id: 'workSection.step.2.content',
      defaultMessage:
        'The application will take a few minutes to run, but data analysis is more complicated. Therefore, it is necessary to organize an online meeting to clarify the details and prepare an action plan.',
    }),
    icon: '/assets/svg/work/conversation.svg',
  },
  {
    id: 3,
    title: defineMessage({
      id: 'workSection.step.3.title',
      defaultMessage: 'Configuration',
    }),
    content: defineMessage({
      id: 'workSection.step.3.content',
      defaultMessage:
        'After obtaining the necessary information, we proceed to configure the system by connecting it to data sources. Depending on the complexity, this step may take several hours or more.',
    }),
    icon: '/img/work_thumb_3.png',
  },
]

const Work = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='How to start'
      id='implementation-section'
      className='section work-area bg-overlay overflow-hidden ptb_100'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10'>
            {/* Work Content */}
            <div className='work-content text-center'>
              <h2 className='text-white'>
                <FormattedMessage
                  id='workSection.heading'
                  defaultMessage='How to start with eKANBAN App?'
                />
              </h2>
              <p className='text-white my-3 mt-sm-4 mb-sm-5'>
                <FormattedMessage
                  id='workSection.headingText'
                  defaultMessage="You don't need to install anything. All you have to do is to follow these steps."
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          {implementation.map(step => {
            return (
              <div key={step.id} className='col-12 col-md-4'>
                {/* Single Work */}
                <div className='single-work text-center p-3'>
                  {/* Work Icon */}
                  <div className='work-icon'>
                    <img
                      className='avatar-md'
                      src={step.icon}
                      alt={intl.formatMessage(step.title)}
                    />
                  </div>
                  <h3 className='text-white py-3'>
                    {intl.formatMessage(step.title)}
                  </h3>
                  <p className='text-white'>
                    {intl.formatMessage(step.content)}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </TrackingSection>
  )
}

export default Work
