import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'

import HeroBackground from './HeroBackground'
import { TrackingSection } from '../Common'

const DEMO_SIGN_IN_URL =
  'https://demo.ekanbanapp.com/users/sign_in?ref=landingpage'

const heroImgAlt = defineMessage({
  id: 'heroSection.imgAlt',
  defaultMessage: 'eKANBAN',
})

const HeroSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='Hero'
      id='home'
      className='section welcome-area bg-inherit h-100vh overflow-hidden'
    >
      <HeroBackground />
      <div className='container h-100'>
        <div className='row align-items-center h-100'>
          {/* Welcome Intro Start */}
          <div className='col-12 col-md-5'>
            <div className='welcome-intro'>
              <h1 className='welcome-intro text-white'>
                <FormattedMessage
                  id='heroSection.heading'
                  defaultMessage='Manage goods delivery with eKANBAN like a PRO'
                />
              </h1>
              <p className='my-4 text-white'>
                <FormattedMessage
                  id='heroSection.content'
                  defaultMessage='eKANBAN is a web application that supports transportation of components and products in a production plant.'
                />
              </p>
              <a
                href={DEMO_SIGN_IN_URL}
                target='_blank'
                rel='noopener noreferrer follow'
                className='btn green'
              >
                <FormattedMessage
                  id='heroSection.btnText'
                  defaultMessage='See the demo'
                />
              </a>
            </div>
          </div>
          <div className='col-12 col-md-7 align-self-end'>
            <div className='welcome-thumb'>
              <img
                src='/img/features_thumb.png'
                alt={intl.formatMessage(heroImgAlt)}
              />
            </div>
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default HeroSection
