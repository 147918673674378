import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'

const DEMO_SIGN_IN_URL =
  'https://demo.ekanbanapp.com/users/sign_in?ref=landingpage'

const logoImageAlt = defineMessage({
  id: 'footer.logoImageAlt',
  defaultMessage: 'eKANBAN app',
})

const footerLinks = [
  {
    id: 1,
    text: defineMessage({
      id: 'footer.links.features',
      defaultMessage: 'Features',
    }),
    link: '#features-section',
  },
  {
    id: 2,
    text: defineMessage({
      id: 'footer.links.implementation',
      defaultMessage: 'Implementation',
    }),
    link: '#implementation-section',
  },
  {
    id: 3,
    text: defineMessage({
      id: 'footer.links.interface',
      defaultMessage: 'Interface',
    }),
    link: '#interface-section',
  },
  {
    id: 4,
    text: defineMessage({
      id: 'footer.links.pricing',
      defaultMessage: 'Pricing',
    }),
    link: '#pricing-section',
  },
  {
    id: 5,
    text: defineMessage({
      id: 'footer.links.contact',
      defaultMessage: 'Contact',
    }),
    link: '#contact-section',
  },
]

const footerOtherApps = [
  {
    id: 1,
    name: 'Action Plan',
    href: 'https://plandzialan.pl/',
  },
  {
    id: 2,
    name: 'Action Plan Pulse',
    href: 'https://plandzialan.pl/pulse',
  },
  {
    id: 3,
    name: 'OEE Tools',
    href: 'https://oeetools.com/',
  },
  {
    id: 4,
    name: 'TPM Tools',
    href: 'https://tpm-tool.com/',
  },
]

const FooterSection = () => {
  const intl = useIntl()

  return (
    <div>
      <div className='height-emulator d-none d-lg-block' />
      <footer className='footer-area footer-fixed'>
        {/* Footer Top */}
        <div className='footer-top ptb_100'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-sm-6 col-lg-3'>
                {/* Footer Items */}
                <div className='footer-items'>
                  {/* Logo */}
                  <a className='navbar-brand' href='./'>
                    <img
                      className='logo'
                      srcSet='/img/logo.png 1x, /img/logo@2x.png 2x'
                      alt={intl.formatMessage(logoImageAlt)}
                    />
                  </a>
                  <p className='mt-2 mb-3'>
                    <FormattedMessage
                      id='footer.about'
                      defaultMessage='Manage the flow of materials and finished products in the plant thanks to the eKANBAN application.'
                    />
                  </p>
                  {/* Social Icons */}
                  <div className='social-icons d-flex'>
                    <a
                      className='linkedin'
                      href='https://linkedin.com/company/ruby-logic/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-linkedin-in' />
                      <i className='fab fa-linkedin-in' />
                    </a>
                    <a
                      className='facebook'
                      href='https://www.facebook.com/rubylogic/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-facebook-f' />
                      <i className='fab fa-facebook-f' />
                    </a>
                    <a
                      className='youtube'
                      href='https://www.youtube.com/channel/UCCf8XuGescbElZMXuD9QBxw'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-youtube' />
                      <i className='fab fa-youtube' />
                    </a>
                    <a
                      className='twitter'
                      href='https://twitter.com/rubylogic'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-twitter' />
                      <i className='fab fa-twitter' />
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-6 col-lg-3'>
                {/* Footer Items */}
                <div className='footer-items'>
                  {/* Footer Title */}
                  <h3 className='footer-title mb-2'>
                    <FormattedMessage
                      id='footer.linksHeader'
                      defaultMessage='eKANBAN'
                    />
                  </h3>
                  <ul>
                    {footerLinks.map(({ id, text, link }) => (
                      <li key={id} className='py-2'>
                        <a href={link} className='scroll'>
                          {intl.formatMessage(text)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='col-12 col-sm-6 col-lg-3'>
                {/* Footer Items */}
                <div className='footer-items'>
                  {/* Footer Title */}
                  <h3 className='footer-title mb-2'>
                    <FormattedMessage
                      id='footer.otherAppsHeader'
                      defaultMessage='Other tools'
                    />
                  </h3>
                  <ul>
                    {footerOtherApps.map(entry => (
                      <li key={entry.id} className='py-2'>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={entry.href}
                        >
                          {entry.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='col-12 col-sm-6 col-lg-3'>
                {/* Footer Items */}
                <div className='footer-items'>
                  {/* Footer Title */}
                  <h3 className='footer-title mb-2'>
                    <FormattedMessage
                      id='footer.demoHeader'
                      defaultMessage='Try the DEMO'
                    />
                  </h3>
                  {/* Store Buttons */}
                  <div className='button-group store-buttons store-black d-flex flex-wrap'>
                    <a
                      href={DEMO_SIGN_IN_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src='/img/ekanban-badge.png'
                        alt={intl.formatMessage(logoImageAlt)}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                {/* Copyright Area */}
                <div className='copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4'>
                  {/* Copyright Left */}
                  <div className='copyright-left'>
                    © All rights reserved Ruby Logic 2020
                  </div>
                  {/* Copyright Right */}
                  <p>
                    Application made by{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://rubylogic.eu/?ref=ekanbanapp'
                    >
                      Ruby Logic Poland Sp. z o.o.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterSection
