import React from 'react'
import debounce from './debounce'

const DEBOUNCE_TIME = 500

const useOnWindowResize = callback => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  React.useEffect(() => {
    const onWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', debounce(onWindowResize, DEBOUNCE_TIME))
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  React.useEffect(() => {
    return callback(windowSize)
  }, [windowSize, callback])

  return windowSize
}

export default useOnWindowResize
