import React, { useState, useCallback } from 'react'

export const useContactFormSelectState = () => {
  const [state, setState] = useState()
  const setSelectState = useCallback(
    val => setState(v => (v !== val ? val : null)),
    []
  )
  return [state, setSelectState]
}

const preventDefault = e => e.preventDefault()
const ContactFormSelect = ({ value, onChange, options, label, id }) => {
  return (
    <div className='input dropdown show'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className='dropdown-toggle'
        role='button'
        href='#'
        id={id}
        onClick={preventDefault}
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <span className='placeholder'>{`${label}${value ? ':' : ''}`}</span>
        {!!value && value}
      </a>
      <div className='dropdown-menu' aria-labelledby={id}>
        {options.map(option => (
          <div
            key={option}
            className={`dropdown-item ${value === option ? 'active' : ''}`}
            onClick={() => onChange(option)}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(ContactFormSelect)
