import ReactGA from 'react-ga'

const MIN_WATCHING_TIME_SEC = 10

class TimeTracking {
  constructor(section) {
    this.section = section
  }

  onInteraction({ isIntersecting }) {
    if (isIntersecting) {
      this.start()
    } else if (this.started) {
      const time = this.end()
      sendTiming({ section: this.section, time })
    }
  }

  start() {
    this.started = new Date().getTime()
  }

  end() {
    const time = new Date().getTime() - this.started
    delete this.started
    return time
  }
}

const sendTiming = ({ section, time }) => {
  const timeInSeconds = Math.round((time + 1) / 1000)
  if (timeInSeconds < MIN_WATCHING_TIME_SEC) {
    return
  }

  ReactGA.timing(
    {
      category: 'Watching Section',
      variable: section,
      value: time,
      label: `Watched ${section} section for ${timeInSeconds}s`,
    },
    ['all', 'one']
  )

  // Send also an event
  ReactGA.event(
    {
      category: 'Was Watching Section',
      action: section,
      label: `Was watching ${section} section for ${timeInSeconds}s`,
    },
    ['all', 'one']
  )
}

export default TimeTracking
