import React from 'react'
import { useIntl } from 'react-intl'

const PricingListEntry = ({ entry, isLast }) => {
  const intl = useIntl()
  const { message, highlighted } = entry

  return (
    <li className={`border-top py-3 ${isLast ? 'border-bottom' : ''}`}>
      {highlighted && (
        <>
          <div className='plan-features-highlight'>
            <div className='plan-features-highlight__icon'>%</div>
            <span className='plan-features-highlight__text'>
              {intl.formatMessage(highlighted)}
            </span>
          </div>
          <br />
        </>
      )}
      {intl.formatMessage(message)}
    </li>
  )
}

export default PricingListEntry
