import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import { TrackingSection } from '../Common'

const faqs = [
  {
    id: 1,
    title: defineMessage({
      id: 'faqSection.title.1',
      defaultMessage: 'How to launch eKANBAN App?',
    }),
    content: defineMessage({
      id: 'faqSection.content.1',
      defaultMessage:
        'The app works fully online. All you need is a browser and internet access.',
    }),
  },
  {
    id: 2,
    title: defineMessage({
      id: 'faqSection.title.2',
      defaultMessage: 'How can the eKANBAN application help me?',
    }),
    content: defineMessage({
      id: 'faqSection.content.2',
      defaultMessage:
        'Thanks to the OEE Tools app you can constantly monitor the utilization level of machines in your production plant.',
    }),
  },
  {
    id: 3,
    title: defineMessage({
      id: 'faqSection.title.3',
      defaultMessage: 'Do you offer a free trial period?',
    }),
    content: defineMessage({
      id: 'faqSection.content.3',
      defaultMessage:
        'YES! Fill in the contact form and we will configure a test instance for you in a matter of days.',
    }),
  },
  {
    id: 4,
    title: defineMessage({
      id: 'faqSection.title.4',
      defaultMessage:
        'Do I have to expand the IT infrastructure to use eKANBAN App?',
    }),
    content: defineMessage({
      id: 'faqSection.content.4',
      defaultMessage:
        "There's no need to. The app works in 'cloud' so you don't need to worry about the technical stuff. We'll take care of everything.",
    }),
  },
  {
    id: 5,
    title: defineMessage({
      id: 'faqSection.title.5',
      defaultMessage:
        "I've got my own idea on how to use the app. Can you help me?",
    }),
    content: defineMessage({
      id: 'faqSection.content.5',
      defaultMessage:
        'Here at Ruby Logic we create software for production plants and medium to large businesses. Send us a message to get an individual offer.',
    }),
  },
]

const FaqSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection section='FAQ' className='section faq-area ptb_100'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-7'>
            {/* Section Heading */}
            <div className='section-heading text-center'>
              <h2>
                <FormattedMessage
                  id='faqSection.heading'
                  defaultMessage='Frequently asked questions'
                />
              </h2>
              <p className='mt-4'>
                <FormattedMessage
                  id='faqSection.headingText'
                  defaultMessage='Find the answers to frequently asked questions about our system.'
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12'>
            {/* FAQ Content */}
            <div className='faq-content'>
              {/* eKBANBANAPP Accordion */}
              <div className='accordion' id='eKBANBANAPP-accordion'>
                <div className='row justify-content-center'>
                  <div className='col-12 col-md-10 col-lg-8'>
                    {/* Single Accordion Item */}
                    {faqs.map(({ id, title, content }) => {
                      return (
                        <div
                          key={id}
                          className='card border-top-0 border-left-0 border-right-0 border-bottom'
                        >
                          {/* Card Header */}
                          <div className='card-header bg-inherit border-0 p-0'>
                            <h2 className='mb-0'>
                              <button
                                className='btn px-0 py-3'
                                type='button'
                                data-toggle='collapse'
                                data-target={`#collapse${id}`}
                              >
                                {intl.formatMessage(title)}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={`collapse${id}`}
                            className='collapse'
                            data-parent='#eKBANBANAPP-accordion'
                          >
                            {/* Card Body */}
                            <div className='card-body px-0 py-3'>
                              {intl.formatMessage(content)}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default FaqSection
