import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import { TrackingSection } from '../Common'

const DEMO_URL = 'https://demo.ekanbanapp.com'

const imageAltMessage = defineMessage({
  id: 'downloadSection.imageAlt',
  defaultMessage: 'eKANBAN app',
})

const Download = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='Download'
      className='section download-area overlay-dark ptb_100'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-8'>
            {/* Download Text */}
            <div className='download-text text-center'>
              <h2 className='text-white'>
                <FormattedMessage
                  id='downloadSection.heading'
                  defaultMessage='Manage materials flow in production plant'
                />
              </h2>
              <p className='text-white my-3'>
                <FormattedMessage
                  id='downloadSection.headingText'
                  defaultMessage='The eKANBAN application is fully online.You only need Internet access and a browser to use it'
                />
              </p>
              {/* Store Buttons */}
              <div className='button-group store-buttons d-flex justify-content-center'>
                <a href={DEMO_URL} rel='noopener noreferrer' target='_blank'>
                  <img
                    src='/img/ekanban-badge-white.png'
                    alt={intl.formatMessage(imageAltMessage)}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default Download
