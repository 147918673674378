import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'

import { TrackingSection } from '../Common'
import { getLocale } from '../../locale'

const baseUrl = `/assets/svg/processes/${getLocale()}`
const processes = [
  {
    id: 1,
    title: defineMessage({
      id: 'serviceSection.processes.1',
      defaultMessage: 'Delivery on demand',
    }),
    svg: `${baseUrl}/delivery-on-demand.svg`,
  },
  {
    id: 2,
    title: defineMessage({
      id: 'serviceSection.processes.2',
      defaultMessage: 'Delivery & Quality Check',
    }),
    svg: `${baseUrl}/delivery-quality-check.svg`,
  },
  {
    id: 3,
    title: defineMessage({
      id: 'serviceSection.processes.3',
      defaultMessage: 'Market supply',
    }),
    svg: `${baseUrl}/market-supply.svg`,
  },
  {
    id: 4,
    title: defineMessage({
      id: 'serviceSection.processes.4',
      defaultMessage: 'Small trains',
    }),
    svg: `${baseUrl}/small-trains.svg`,
  },
]

const fadeDuration = 200
const ServiceSectionSvg = ({ process: processParam }) => {
  const intl = useIntl()
  const firstRender = React.useRef(true)
  const ref = React.useRef()
  const [process, setProcess] = React.useState(processParam)

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    window.$(ref.current).fadeOut(fadeDuration, () => {
      setProcess(processParam)
      window.$(ref.current).fadeIn(fadeDuration)
    })
  }, [processParam])

  const { title, svg } = process
  return <img src={svg} alt={intl.formatMessage(title)} ref={ref} />
}

const ServiceSection = () => {
  const intl = useIntl()
  const [activeProcess, setActiveProcess] = React.useState(processes[0])
  const isActive = id => id === activeProcess.id

  return (
    <TrackingSection
      section='Processes'
      className='section service-area bg-inherit overflow-hidden pt_100'
    >
      <div className='container mb-5 mb-md-0'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-8'>
            <div className='service-text pt-4 pb-5 pt-lg-0'>
              <h2 className='mb-4 text-center'>
                <FormattedMessage
                  id='serviceSection.heading'
                  defaultMessage='Example processes handled using eKANBAN'
                />
              </h2>
            </div>
          </div>
        </div>
        <div className='row' style={{ minHeight: 720 }}>
          <div className='col-12 col-md-6 pr-lg-5 pr-md-3 px-sm-2 order-2 order-md-1'>
            <div className='d-flex justify-content-center mb-5'>
              <ServiceSectionSvg process={activeProcess} />
            </div>
          </div>
          <div className='col-12 col-md-6 pl-lg-5 pl-md-3 px-sm-2 mb-5 mb-md-0 order-1 order-md-2'>
            <div className='processes-menu'>
              {processes.map(process => {
                const activeClass =
                  isActive(process.id) && 'processes-menu__button--active'

                return (
                  <button
                    key={process.id}
                    className={`processes-menu__button ${activeClass || ''}`}
                    onClick={() => setActiveProcess(process)}
                  >
                    {intl.formatMessage(process.title)}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default ServiceSection
