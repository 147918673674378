import React from 'react'

import useTrackingSection from '../../lib/useTrackingSection'

const TrackingSection = ({ section, children, ...props }) => {
  const ref = useTrackingSection(section)

  return (
    <section {...props} ref={ref}>
      {children}
    </section>
  )
}

export default TrackingSection
