import ReactGA from 'react-ga'

export const initGoogleAnalytics = () => {
  ReactGA.initialize(
    [
      {
        trackingId: 'UA-2084640-7',
        gaOptions: {
          name: 'all',
          sampleRate: 100,
          siteSpeedSampleRate: 100,
        },
      },
      {
        trackingId: 'UA-2084640-5',
        gaOptions: {
          name: 'one',
          sampleRate: 100,
          siteSpeedSampleRate: 100,
        },
      },
    ],
    {
      debug: false,
      alwaysSendToDefaultTracker: false,
    }
  )
}
