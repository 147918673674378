import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'

import { TrackingSection } from '../Common'
import ContactForm from './ContactForm'

const contacts = [
  {
    id: 1,
    text: defineMessage({
      id: 'contactSection.1',
      defaultMessage: 'Bielsko-Biała, PL',
    }),
    icon: 'fas fa-home',
  },
  {
    id: 2,
    text: defineMessage({
      id: 'contactSection.2',
      defaultMessage: '+48 880 030 220',
    }),
    icon: 'fas fa-phone-alt',
  },
  {
    id: 3,
    text: defineMessage({
      id: 'contactSection.3',
      defaultMessage: 'info@rubylogic.pl',
    }),
    icon: 'fas fa-envelope',
  },
]

const ContactSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='Contact'
      id='contact-section'
      className='contact-area bg-gray ptb_100'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            {/* Section Heading */}
            <div className='section-heading text-center'>
              <h2 className='text-white'>
                <FormattedMessage
                  id='contactSection.heading'
                  defaultMessage='Request a demo'
                />
              </h2>
              <p className='mt-4 text-white'>
                <FormattedMessage
                  id='contactSection.headingText'
                  defaultMessage='Do you want to test our system? Do you have questions or doubts how the eKANBAN application can affect the management of the flow of materials and finished products in your plant? Send us a message, we will be happy to answer any question.'
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-5'>
            {/* Contact Us */}
            <div className='contact-us'>
              <p className='mb-3 text-white'>
                <FormattedMessage
                  id='contactSection.content'
                  defaultMessage='Ruby Logic Poland Sp. z o.o. Our Logic - Your Business.'
                />
              </p>
              <ul>
                {contacts.map(({ id, text, icon }) => {
                  return (
                    <li key={id} className='py-2'>
                      <a className='media' href='/#'>
                        <div className='social-icon mr-3'>
                          <i className={icon} />
                        </div>
                        <span className='media-body align-self-center text-white'>
                          {intl.formatMessage(text)}
                        </span>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className='col-12 col-md-6 pt-4 pt-md-0'>
            <ContactForm />
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default ContactSection
