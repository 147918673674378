import React, { useState, useCallback, useRef } from 'react'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'

import ContactFormSelect, {
  useContactFormSelectState,
} from './ContactFormSelect'

const MESSAGES_API_URL = process.env.NODE_ENV === 'development'
                          ? process.env.REACT_APP_DEV_MESSAGES_API_URL
                          : process.env.REACT_APP_PROD_MESSAGES_API_URL

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const NUMBER_OF_ORDERS_OPTIONS = [
  '1-100',
  '101-200',
  '201-500',
  '501-1000',
  '+1001',
]

const messages = defineMessages({
  name: {
    id: 'contactForm.placeholder.name',
    defaultMessage: 'Full name',
  },
  email: {
    id: 'contactForm.placeholder.email',
    defaultMessage: 'Email address',
  },
  numberOfOrders: {
    id: 'contactForm.placeholder.numberOfOrders',
    defaultMessage: 'Estimated number of orders daily',
  },
  message: {
    id: 'contactForm.placeholder.message',
    defaultMessage: 'Message',
  },
  thanks_message: {
    id: 'contactForm.thanks_message',
    defaultMessage: 'Thank you',
  },
  send_next_message: {
    id: 'contactForm.send_next_message',
    defaultMessage: 'Send next message',
  },
  try_again: {
    id: 'contactForm.try_again',
    defaultMessage: 'Try again',
  },
  error: {
    id: 'contactForm.error',
    defaultMessage: 'Error occurred',
  },
})

const useInputState = (def = '') => {
  const [state, setState] = useState(def)
  const setInputState = useCallback(({ target }) => setState(target.value), [])
  return [state, setInputState]
}

const ContactForm = () => {
  const [status, setStatus] = useState('ready')

  const intl = useIntl()
  const [name, setName] = useInputState()
  const [email, setEmail] = useInputState()
  const [ordersNo, setOrdersNo] = useContactFormSelectState()
  const [message, setMessage] = useInputState()
  const recaptchaRef = useRef()
  const [captchaError, setCaptchaError] = useState('')

  const prepareNewMessage = () => {
    setMessage({ target: { value: '' }});
    setStatus('ready')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if(!validateForm()) { return }

    const data = {
      app: 'eKANBAN',
      user_name: name,
      user_email: email,
      content: { message: message, ordersNo: ordersNo },
      captcha: recaptchaRef.current.getValue()
    };
    const response = await fetch(MESSAGES_API_URL, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ message: data })
    })
    setStatus(response.ok ? 'sent' : 'error');
  }

  const validateForm = () => {
    recaptchaRef.current.getValue() === '' && handleRecaptchaChange()
    return name !== '' && email !== '' && recaptchaRef.current.getValue() !== ''
  }

  const handleRecaptchaChange = () => {
    setCaptchaError(recaptchaRef.current.getValue() === '' ? 'Provide captcha' : '')
  }

  switch (status) {
    case 'ready':
      return (
        <div className='contact-box text-center'>
          <form
            className='contact-form'
            onSubmit={handleSubmit}
          >
            <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder={intl.formatMessage(messages.name)}
                    required='required'
                    onChange={setName}
                    value={name}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    placeholder={intl.formatMessage(messages.email)}
                    required='required'
                    onChange={setEmail}
                    value={email}
                  />
                </div>
                <div className='form-group'>
                  <ContactFormSelect
                    id='numberOfUsers'
                    value={ordersNo}
                    onChange={setOrdersNo}
                    options={NUMBER_OF_ORDERS_OPTIONS}
                    label={intl.formatMessage(messages.numberOfOrders)}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <textarea
                    className='form-control'
                    name='message'
                    placeholder={intl.formatMessage(messages.message)}
                    required='required'
                    onChange={setMessage}
                    value={message}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  {
                    RECAPTCHA_SITE_KEY &&
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={handleRecaptchaChange}
                      />
                  }
                  <span style={{display:'block',textAlign:'left',color:'red',fontWeight:'bold'}}>
                    {captchaError}
                  </span>
                </div>
              </div>
              <div className='col-12'>
                <button type='submit' className='btn btn-lg btn-block mt-3'>
                  <span className='text-white pr-3'>
                    <i className='fas fa-paper-plane' />
                  </span>
                  <FormattedMessage
                    id='contactForm.submit'
                    defaultMessage='Send a message'
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      )
    case 'sent':
      return (
        <React.Fragment>
          <h4 className="text-white mb-4">{intl.formatMessage(messages.thanks_message)}</h4>
          <a className='media text-white' href='/#' onClick={(e) => { e.preventDefault(); prepareNewMessage() }}>
            <div className='social-icon mr-3'>
              <i className='fas fa-redo' />
            </div>
            <span className='media-body align-self-center text-white'>
              {intl.formatMessage(messages.send_next_message)}
            </span>
          </a>
        </React.Fragment>
      )
    case 'error':
      return(
        <React.Fragment>
          <h4 className="text-white mb-4">{intl.formatMessage(messages.error)}</h4>
          <a className='media text-white' href='/#' onClick={(e) => { e.preventDefault(); setStatus('ready') }}>
            <div className='social-icon mr-3'>
              <i className='fas fa-redo' />
            </div>
            <span className='media-body align-self-center text-white'>
              {intl.formatMessage(messages.try_again)}
            </span>
          </a>
        </React.Fragment>
      )
    default:
      break;
  }
}

export default React.memo(ContactForm)
