import React from 'react'
import { getLocale, setLocale, AVAILABLE_LOCALES } from '../../locale'

const LanguageSwitch = () => {
  return (
    <li className='nav-item language-switch dropdown'>
      <a
        className='nav-link dropdown-toggle'
        href='#lang'
        onClick={e => e.preventDefault()}
        id='language-switch'
        role='button'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {getLocale()}
      </a>
      <div className='dropdown-menu' aria-labelledby='language-switch'>
        {Object.values(AVAILABLE_LOCALES).map(locale => (
          <a
            key={locale}
            className='dropdown-item'
            href='#lang'
            onClick={e => setLocale(locale) || e.preventDefault()}
          >
            {locale}
          </a>
        ))}
      </div>
    </li>
  )
}

export default LanguageSwitch
