import React from 'react'
import ReactGA from 'react-ga'

import { initGoogleAnalytics } from './googleAnalytics'
import MyRouts from './routers/routes'

initGoogleAnalytics()

const App = () => {
  React.useEffect(() => {
    const url = window.location.pathname + window.location.search
    ReactGA.pageview(url, ['all', 'one'])
  }, [])

  return (
    <div>
      <MyRouts />
    </div>
  )
}

export default App
