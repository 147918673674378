import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import { TrackingSection } from '../Common'
import { AVAILABLE_LOCALES, getLocale } from '../../locale'

const YT_EMBEDDED_URLS = {
  [AVAILABLE_LOCALES.en]: 'https://www.youtube.com/embed/c4oY8TAUq0Q',
  [AVAILABLE_LOCALES.pl]: 'https://www.youtube.com/embed/3mpWHGf6JK0',
}
const YT_RATIO = '16by9'

const videoTitleMessage = defineMessage({
  id: 'howItWorks.videoTitle',
  defaultMessage: 'eKANBAN video',
})

const HowItWorksSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='How it works'
      id='how-it-works-section'
      className='section ptb_60 pb-5'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-7'>
            <div className='section-heading text-center mb-5'>
              <h2>
                <FormattedMessage
                  id='howItWorks.heading'
                  defaultMessage='How it works?'
                />
              </h2>
              <p className='mt-4'>
                <FormattedMessage
                  id='howItWorks.headingText'
                  defaultMessage='See how our app handle the production flow.'
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6'>
            <div
              className={`embed-responsive embed-responsive-${YT_RATIO} bg-light`}
            >
              <iframe
                className='embed-responsive-item'
                title={intl.formatMessage(videoTitleMessage)}
                frameBorder='0'
                src={YT_EMBEDDED_URLS[getLocale()]}
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </TrackingSection>
  )
}

export default HowItWorksSection
