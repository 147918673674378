import React from 'react'

const HeroBackground = () => {
  return (
    <div className='bg-container'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 200 200'
        preserveAspectRatio='xMidYMax slice'
        className='bg'
      >
        <defs>
          <linearGradient id='bg-gradient' x2='1' y2='1.2'>
            <stop offset='0%' stopColor='#804399' />
            <stop offset='100%' stopColor='#2B0C3D' />
          </linearGradient>
        </defs>
        <path
          d='M 0 0 L 196 0 C 170 210 210 210 0 160 L 0 0 '
          fill='url(#bg-gradient)'
        />
      </svg>
    </div>
  )
}

export default HeroBackground
