import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import LanguageSwitch from './LanguageSwitch'

const logoImageAlt = defineMessage({
  id: 'header.logoImageAlt',
  defaultMessage: 'eKANBAN app',
})

const toggleNavigation = defineMessage({
  id: 'header.toggleNavigation',
  defaultMessage: 'Toggle navigation',
})

const Header = () => {
  const intl = useIntl()

  return (
    <header className='navbar navbar-sticky navbar-expand-lg navbar-dark'>
      <div className='container position-relative'>
        <a className='navbar-brand' href='./'>
          <img
            className='navbar-brand-regular'
            srcSet='/img/logo-white.png 1x, /img/logo-white@2x.png 2x'
            alt={intl.formatMessage(logoImageAlt)}
          />
          <img
            className='navbar-brand-sticky'
            srcSet='/img/logo.png 1x, /img/logo@2x.png 2x'
            alt={intl.formatMessage(logoImageAlt)}
          />
        </a>
        <button
          className='navbar-toggler d-lg-none'
          type='button'
          data-toggle='navbarToggler'
          aria-label={intl.formatMessage(toggleNavigation)}
        >
          <span className='navbar-toggler-icon' />
        </button>
        <div className='navbar-inner'>
          {/*  Mobile Menu Toggler */}
          <button
            className='navbar-toggler d-lg-none'
            type='button'
            data-toggle='navbarToggler'
            aria-label={intl.formatMessage(toggleNavigation)}
          >
            <span className='navbar-toggler-icon' />
          </button>
          <nav>
            <ul className='navbar-nav' id='navbar-nav'>
              <li className='nav-item dropdown'>
                <a className='nav-link scroll' href='#home'>
                  <FormattedMessage
                    id='header.menu.home'
                    defaultMessage='eKANBAN'
                  />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link scroll' href='#features-section'>
                  <FormattedMessage
                    id='header.menu.features'
                    defaultMessage='Features'
                  />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link scroll' href='#implementation-section'>
                  <FormattedMessage
                    id='header.menu.implementation'
                    defaultMessage='Implementation'
                  />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link scroll' href='#interface-section'>
                  <FormattedMessage
                    id='header.menu.interface'
                    defaultMessage='Interface'
                  />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link scroll' href='#pricing-section'>
                  <FormattedMessage
                    id='header.menu.pricing'
                    defaultMessage='Pricing'
                  />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link scroll' href='#contact-section'>
                  <FormattedMessage
                    id='header.menu.contact'
                    defaultMessage='Contact'
                  />
                </a>
              </li>
              <LanguageSwitch />
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
