import React from 'react'

import useOnWindowResize from './useOnWindowResize'
import { TimeTracking, ReadingTracking } from './trackings'

const useTrackingsRef = section => {
  const trackingsRef = React.useRef()

  React.useEffect(() => {
    trackingsRef.current = {
      time: new TimeTracking(section),
      reading: new ReadingTracking(section),
    }
  }, [section])

  return trackingsRef
}

const useTrackingSection = section => {
  const ref = React.useRef()
  const trackingsRef = useTrackingsRef(section)

  useOnWindowResize(
    React.useCallback(() => {
      const callback = entries => {
        const entry = entries[0]
        if (entry) {
          trackingsRef.current.time.onInteraction(entry)
          trackingsRef.current.reading.onInteraction(entry)
        }
      }

      const threshold = calculateThreshold(ref.current)
      const observer = new IntersectionObserver(callback, {
        threshold,
      })

      observer.observe(ref.current)
      return () => observer.disconnect()
    }, [trackingsRef])
  )

  return ref
}

const calculateThreshold = target => {
  const threshold =
    window.innerHeight / target.getBoundingClientRect().height - 0.1
  return Math.max(0.1, Math.min(threshold, 0.9))
}

export default useTrackingSection
