import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import IntlProvider from '../Intl'
import ThemeTwo from '../themes/theme-two'

const NormalThemeTwo = () => (
  <IntlProvider>
    <ThemeTwo />
  </IntlProvider>
)

const ENThemeTwo = () => (
  <IntlProvider locale='en'>
    <ThemeTwo />
  </IntlProvider>
)

const PLThemeTwo = () => (
  <IntlProvider locale='pl'>
    <ThemeTwo />
  </IntlProvider>
)

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path='/' component={NormalThemeTwo} />
            <Route exact path='/en' component={ENThemeTwo} />
            <Route exact path='/pl' component={PLThemeTwo} />
          </Switch>
        </Router>
      </div>
    )
  }
}
export default MyRouts
