import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'
import { TrackingSection } from '../Common'

const features = [
  {
    id: 1,
    title: defineMessage({
      id: 'featuresSection.features.1.title',
      defaultMessage: 'Available on-line',
    }),
    text: defineMessage({
      id: 'featuresSection.features.1.text',
      defaultMessage:
        'No more complicated software installation! No more network configuration problems! No time wasted on IT support! eKANBAN is an on-line application available as a service. It does not require installation of any software or hardware so your can start using eKANBAN immediately.',
    }),
    icon: '/assets/svg/features/pc.svg',
  },
  {
    id: 2,
    title: defineMessage({
      id: 'featuresSection.features.2.title',
      defaultMessage: 'Mobile ready',
    }),
    text: defineMessage({
      id: 'featuresSection.features.2.text',
      defaultMessage:
        'eKANBAN is designed to work well on mobile devices. Production operators and forklift drivers are able to use tablets or even smart phones to process orders. Big touch areas make the application easy to operate even in tough environment of a production plant.',
    }),
    icon: '/assets/svg/features/tablet.svg',
  },
  {
    id: 3,
    title: defineMessage({
      id: 'featuresSection.features.3.title',
      defaultMessage: 'Instant analysis',
    }),
    text: defineMessage({
      id: 'featuresSection.features.3.text',
      defaultMessage:
        'eKANBAN has a built-in analytics module that will give you an instant information about key performance indicators important for quick process improvement. The module also lets you export data to CSV or XLSX formats for further analysis.',
    }),
    icon: '/assets/svg/features/google-analytics.svg',
  },
  {
    id: 4,
    title: defineMessage({
      id: 'featuresSection.features.4.title',
      defaultMessage: 'User friendly',
    }),
    text: defineMessage({
      id: 'featuresSection.features.4.text',
      defaultMessage:
        "We've spent a lot of time making eKANBAN's interface user friendly and clean. We mus admit that the goal has been achieved! Using eKANBAN you'll experience an intuitive and easy to use application. This significantly shortens onboarding time.",
    }),
    icon: '/assets/svg/features/team2.svg',
  },
  {
    id: 5,
    title: defineMessage({
      id: 'featuresSection.features.5.title',
      defaultMessage: 'Highly configurable',
    }),
    text: defineMessage({
      id: 'featuresSection.features.5.text',
      defaultMessage:
        'eKANBAN is delivered with an extensive admin panel where you can configure and manage almost every area of the application and shape a transportation process that fits your requirements. Stocks, locations, warehouses, plant layout and other elements - what you can modify instantly.',
    }),
    icon: '/assets/svg/features/settings.svg',
  },
  {
    id: 6,
    title: defineMessage({
      id: 'featuresSection.features.6.title',
      defaultMessage: 'Extendable',
    }),
    text: defineMessage({
      id: 'featuresSection.features.6.text',
      defaultMessage:
        "You can start with a simple plan and as your business grows you can easily upgrade a subscription. If none of our plans fits your requirements you can ask for a custom plan - we'll prepare it just for you.",
    }),
    icon: '/assets/svg/features/extend.svg',
  },
]

const FeatureSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='Features'
      id='features-section'
      className='section features-area ptb_100'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            {/* Section Heading */}
            <div className='section-heading text-center'>
              <h2>
                <FormattedMessage
                  id='featuresSection.heading'
                  defaultMessage='Features'
                />
              </h2>
              <p className='mt-4'>
                <FormattedMessage
                  id='featuresSection.headingText'
                  defaultMessage='See the main features that the app has to offer.'
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          {features.map(({ id, title, text, icon }) => {
            return (
              <div key={id} className='col-12 col-md-6 col-lg-4'>
                {/* Icon Box */}
                <div className='icon-box text-center p-4'>
                  {/* Featured Icon */}
                  <div className='featured-icon mb-3'>
                    <img src={icon} alt={intl.formatMessage(title)} />
                  </div>
                  {/* Icon Text */}
                  <div className='icon-text'>
                    <h3 className='mb-2'>{intl.formatMessage(title)}</h3>
                    <p>{intl.formatMessage(text)}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </TrackingSection>
  )
}

export default FeatureSection
