import React from 'react'
import { useIntl, defineMessage, FormattedMessage } from 'react-intl'

import { TrackingSection } from '../Common'
import PricingListEntry from './PricingListEntry'

const pricing = [
  {
    id: 1,
    title: defineMessage({
      id: 'pricingSection.plan.1.title',
      defaultMessage: 'Starter',
    }),
    prices: {
      annually: {
        price: defineMessage({
          id: 'pricingSection.plan.1.priceAnnually',
          defaultMessage: '99',
        }),
        currency: defineMessage({
          id: 'pricingSection.plan.1.priceAnnuallyCurrency',
          defaultMessage: '€',
        }),
      },
      monthly: {
        price: defineMessage({
          id: 'pricingSection.plan.1.priceMonthly',
          defaultMessage: '119',
        }),
        currency: defineMessage({
          id: 'pricingSection.plan.1.priceMonthlyCurrency',
          defaultMessage: '€',
        }),
      },
    },
    messages: [
      {
        message: defineMessage({
          id: 'pricingSection.plan.1.1',
          defaultMessage: '5 USERS',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.1.2',
          defaultMessage: '1 PRODUCTION LINE',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.1.3',
          defaultMessage: 'FAQ & FORUM ACCESS',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.1.4',
          defaultMessage: '---',
        }),
      },
    ],
    icon: '/assets/svg/pricings/manufacture.svg',
  },
  {
    id: 2,
    title: defineMessage({
      id: 'pricingSection.plan.2.title',
      defaultMessage: 'FACTORY',
    }),
    prices: {
      annually: {
        price: defineMessage({
          id: 'pricingSection.plan.2.priceAnnually',
          defaultMessage: '249',
        }),
        currency: defineMessage({
          id: 'pricingSection.plan.2.priceAnnuallyCurrency',
          defaultMessage: '€',
        }),
      },
      monthly: {
        price: defineMessage({
          id: 'pricingSection.plan.2.priceMonthly',
          defaultMessage: '299',
        }),
        currency: defineMessage({
          id: 'pricingSection.plan.2.priceMonthlyCurrency',
          defaultMessage: '€',
        }),
      },
    },
    messages: [
      {
        message: defineMessage({
          id: 'pricingSection.plan.2.1',
          defaultMessage: '20 USERS',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.2.2',
          defaultMessage: '10 PRODUCTION LINES',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.2.3',
          defaultMessage: 'BUSINESS DAYS SUPPORT',
        }),
      },
      {
        highlighted: defineMessage({
          id: 'pricingSection.plan.2.4.highlighted',
          defaultMessage: 'UP TO €300 OFF',
        }),
        message: defineMessage({
          id: 'pricingSection.plan.2.4',
          defaultMessage: 'FOR IMPLEMENTATION',
        }),
      },
    ],
    icon: '/assets/svg/pricings/factory.svg',
  },
  {
    id: 3,
    title: defineMessage({
      id: 'pricingSection.plan.3.title',
      defaultMessage: 'INDUSTRY',
    }),
    prices: {
      annually: {
        price: defineMessage({
          id: 'pricingSection.plan.3.priceAnnually',
          defaultMessage: '649',
        }),
        currency: defineMessage({
          id: 'pricingSection.plan.3.priceAnnuallyCurrency',
          defaultMessage: '€',
        }),
      },
      monthly: {
        price: defineMessage({
          id: 'pricingSection.plan.3.priceMonthly',
          defaultMessage: '779',
        }),
        currency: defineMessage({
          id: 'pricingSection.plan.3.priceMonthlyCurrency',
          defaultMessage: '€',
        }),
      },
    },
    messages: [
      {
        message: defineMessage({
          id: 'pricingSection.plan.3.1',
          defaultMessage: '100 USERS',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.3.2',
          defaultMessage: '50 PRODUCTION LINES',
        }),
      },
      {
        message: defineMessage({
          id: 'pricingSection.plan.3.3',
          defaultMessage: '24/7 SUPPORT',
        }),
      },
      {
        highlighted: defineMessage({
          id: 'pricingSection.plan.3.4.highlighted',
          defaultMessage: 'UP TO €750 OFF',
        }),
        message: defineMessage({
          id: 'pricingSection.plan.3.4',
          defaultMessage: 'FOR IMPLEMENTATION',
        }),
      },
    ],
    icon: '/assets/svg/pricings/factory-2.svg',
  },
]

const isLast = (array, index) => array.length - 1 === index

const PricingSection = () => {
  const intl = useIntl()

  return (
    <TrackingSection
      section='Pricing'
      id='pricing-section'
      className='section price-plan-area bg-gray overflow-hidden ptb_100'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-7 text-white'>
            {/* Section Heading */}
            <div className='section-heading text-center'>
              <h2 className='text-white'>
                <FormattedMessage
                  id='pricingSection.heading'
                  defaultMessage='Pricing'
                />
              </h2>
              <p className='mt-4 text-white'>
                <FormattedMessage
                  id='pricingSection.headingText'
                  defaultMessage='This is the first step to launch eKANBAN in your plant. Fill out the form below and we will contact you as soon as possible.'
                />
              </p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <div className='row justify-content-center price-plan-wrapper'>
              {pricing.map(({ id, title, prices, messages, icon }) => (
                <div key={id} className='col-12 col-md-6 col-lg-4 mt-4'>
                  <div className='single-price-plan text-center p-5'>
                    <div className='plan-thumb'>
                      <img
                        className='avatar-lg'
                        src={icon}
                        alt={intl.formatMessage(title)}
                      />
                    </div>
                    <div className='plan-title mt-2 mb-3 mt-sm-3 mb-sm-4'>
                      <h3 className='text-uppercase'>
                        {intl.formatMessage(title)}
                      </h3>
                    </div>
                    <div className='plan-price pb-2 pb-sm-3'>
                      <h1 style={{ lineHeight: 1 }} className='color-primary'>
                        <small className='fw-7'>
                          {intl.formatMessage(prices.annually.currency)}
                        </small>
                        {intl.formatMessage(prices.annually.price)}
                      </h1>
                      <small>
                        {intl.formatMessage(prices.monthly.currency)}
                        {intl.formatMessage(prices.monthly.price)}
                        {'/'}
                        <FormattedMessage
                          id='pricingSection.plan.month'
                          defaultMessage='month'
                        />{' '}
                        <FormattedMessage
                          id='pricingSection.plan.billedMonthly'
                          defaultMessage='when billed monthly'
                        />
                      </small>
                    </div>
                    <div className='plan-description'>
                      <ul className='plan-features'>
                        {messages.map((entry, index) => (
                          <PricingListEntry
                            key={entry.message.id}
                            entry={entry}
                            isLast={isLast(messages, index)}
                          />
                        ))}
                        <li className='border-top border-bottom py-3'>
                          <a href='#faq' className='scroll'>
                            <FormattedMessage
                              id='pricingSection.planDetailsTextLink'
                              defaultMessage='See plan details'
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='row justify-content-center pt-5 text-white'>
          <p className='text-white mx-4 mx-sm-0 pt-4 fw-5'>
            <FormattedMessage
              id='pricingSection.sendEmailText'
              defaultMessage='Are you not sure which offer to choose? Leave us a message.'
            />{' '}
            <a className='text-white scroll' href='/#contact-section'>
              <FormattedMessage
                id='pricingSection.sendEmailTextLink'
                defaultMessage='Fill in the form'
              />
            </a>
          </p>
        </div>
      </div>
    </TrackingSection>
  )
}

export default PricingSection
